export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75')
];

export const server_loads = [];

export const dictionary = {
		"/": [22],
		"/activation": [23,[2]],
		"/activation/confirmation": [24,[2]],
		"/admin": [25,[3]],
		"/admin/announcement": [26,[3]],
		"/admin/announcement/new": [27,[3]],
		"/admin/announcement/preview": [28,[4]],
		"/admin/announcement/update/[announcementId]": [29,[3]],
		"/admin/users": [30,[3]],
		"/ai": [31,[5]],
		"/ai/collections": [32,[5]],
		"/ai/companies": [33,[5]],
		"/ai/competitive-analysis": [34,[5,6]],
		"/ai/doc-consult": [35,[5]],
		"/ai/documents": [36,[5,7]],
		"/ai/home": [37,[5,8]],
		"/ai/open-consult": [38,[5]],
		"/ai/organisation": [39,[5]],
		"/ai/profile": [40,[5]],
		"/ai/purchase": [41,[5]],
		"/ai/register": [42,[9]],
		"/ai/reports": [43,[5,10]],
		"/ai/sign-in": [44,[11]],
		"/ai/sign-in/sso/confirmation": [45,[11]],
		"/ai/sustainability-analysis": [46,[5,12]],
		"/ai/welcome": [47,[5]],
		"/email-verify/[...activationdata]": [48],
		"/platform/i18n": [50],
		"/platform/view-org": [51,[13]],
		"/platform/[slug]/users/onboarding": [49],
		"/purpose": [52],
		"/purpose/announcement/[announcementId]/[announcementTitleSlug]": [53,[14]],
		"/purpose/campaigns": [58,[14]],
		"/purpose/campaign": [54],
		"/purpose/campaign/[campaignHandle]": [55,[14]],
		"/purpose/campaign/[campaignHandle]/action/[actionType]/[actionHandle]": [56,[14]],
		"/purpose/campaign/[campaignHandle]/learn/[learnHandle]": [57,[14,15]],
		"/purpose/discuss": [59,[14,16]],
		"/purpose/discuss/[handle]": [60,[14,16]],
		"/purpose/impact": [61,[14]],
		"/purpose/learn": [62,[14,17]],
		"/purpose/profile": [63,[14]],
		"/purpose/profile/edit": [64,[14]],
		"/purpose/team": [65,[14]],
		"/purpose/training": [66,[14]],
		"/purpose/training/[handle]": [67,[14]],
		"/reset-password": [68,[18]],
		"/sign-in": [69,[19]],
		"/sign-in/password": [70,[19]],
		"/sign-in/sso": [71,[19]],
		"/sign-in/sso/confirmation": [72],
		"/unauthorized": [73],
		"/unsubscribe": [74,[20]],
		"/wellness/[...subroute]": [75,[21]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';